<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown">
    <v-card class="my-5 py-3 px-5">
      <v-card-title class="font-weight-bold">
        お問い合わせ
      </v-card-title>

      <v-card-text class="ml-3">
        <p class="mb-3">以下の内容にご了承いただいた上でお問い合わせください。</p>
        <p>・お問い合わせに対する回答に日数を要する場合があります。</p>
        <p>・お問い合わせの内容によっては回答できない場合があります。</p>
      </v-card-text>

      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model.trim="shopName"
                label="店舗名"
                required
                counter="50"
                :rules="[valiRules.required, valiRules.max50]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model.trim="shopArea"
                label="店舗所在地の都道府県＆市町村"
                required
                counter="50"
                :rules="[valiRules.required, valiRules.max50]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model.trim="email"
                label="メールアドレス"
                required
                counter="50"
                :rules="[valiRules.required, valiRules.email, valiRules.max50]"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="inquiry"
                filled
                min-height="300"
                label="お問い合わせの内容"
                required
                counter="1000"
                :rules="[valiRules.required, valiRules.max1000]"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-card-actions class="mt-5">
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="primary"
              @click="submitForm"
            >
              送信
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>

      <!-- ローダー -->
      <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute top
      color="primary"
      ></v-progress-linear>
    </v-card>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import axios from 'axios'
import $literals from '@/literals.js'
import { API_ENDPOINT } from '@/literals.js'
import { CheckTokenError, ValidationRules } from '@/module.js'

export default {
  components: {
  },

  props: {
  },

  data() {
    return {
      shopName: '',
      shopArea: '',
      email: '',
      inquiry: '',
      loading: false,
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
    };
  },

  computed: {
  },

  created() {
  },

  methods: {
    submitForm() {
      if (!this.$refs['form'].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true

      const inquiryData = {
        'shopName': this.shopName,
        'shopArea': this.shopArea,
        'email': this.email,
        'inquiry': this.inquiry
      }
      const payload = JSON.stringify(inquiryData)

      axios({
        ...{ method: 'POST' },
        ...{ url: API_ENDPOINT + '/email-inquiry/' },
        ...{ data: payload },
      })
      .then(() => {
        this.snackbar = {...{color:'info', message: $literals.MESSAGE.successSendSubmit, open: true}}
      })
      .catch(error => CheckTokenError(error))
      .then(() => this.loading = false )
    },
  }
}
</script>

<style scoped>
</style>
